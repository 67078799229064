html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}

/* Custom: Toast - begins */
.Toastify .Toastify__toast-body {
  margin: auto 8px;
}

.Toastify .Toastify__toast {
  border-radius: 4px;
}
/* Custom: Toast - ends */

/* Custom: React to Print - begins */
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
/* Custom: React to Print - ends */
